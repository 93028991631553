import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";

import Alan from "../images/alan.jpg"

const faqs = [
    {
      id: 1,
      question: "2007",
      answer:
        "Founded in Melbourne, Australia",
    },
    {
        id: 2,
        question: "2009",
        answer:<><a href='https://usabilityhub.com'>UsabilityHub</a> launched.</>,
      },
      {
          id: 3,
          question: "2011",
          answer:<><a href='https://bugherd.com'>BugHerd</a> launched.</>,
        },
        {
            id: 4,
            question: "2015",
            answer:
              "RightGIF launched",
          },
          
        {
            id: 5,
            question: "2022",
            answer:
              "Game development studio launched",
          },
          
  ]

export default function Home() {
    return (
        <>
            <Header />
            <div className="bg-white overflow-hidden">
            <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                <div>
                    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    About us
                    </h3>
                </div>
                </div>
                <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="relative lg:row-start-1 lg:col-start-2">
                    <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                    <figure>
                        <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                        <img
                            className="rounded-lg shadow-lg object-cover object-center"
                            src={Alan}
                            alt="Alan leaning on a wall in Melbourne"
                            width={1184}
                            height={1376}
                        />
                        </div>
                        <figcaption className="mt-3 flex text-sm text-gray-500">
                        <span className="ml-2">Alan Downie, Angry Monkeys founder and CEO</span>
                        </figcaption>
                    </figure>
                    </div>
                </div>
                <div className="mt-8 lg:mt-0">
                    <div className="text-base max-w-prose mx-auto lg:max-w-none">
                    <p className="text-lg text-gray-500">
                    Angry Monkeys was founded by Alan Downie in 2007 as collaborative workplace creating unique experiences, software and technology products.
                    </p>
                    <p className="text-lg text-gray-500">
                    After 15 years in technology, Angry Monkeys has recently launched a game development studio focused on delivering interactive fiction backed by quality storytelling.
                    </p>
                                    <p className="mt-10 text-lg text-gray-500"><a href="mailto:alan@angrymonkeys.com.au">alan@angrymonkeys.com.au</a></p>
                    </div>
                    
                    <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                    {faqs.map((faq) => (
                    <div key={faq.id} className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
                        <dt className="text-base font-medium text-gray-900 md:col-span-3">{faq.question}</dt>
                        <dt className="text-base font-medium text-gray-900 md:col-span-2">-</dt>
                        <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                        </dd>
                    </div>
                    ))}
                    </div>
                </div>
                </div>
            </div>
            </div>
            <Footer />
        </>
    );
}
